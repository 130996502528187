@media (max-width: 575.98px) {
  .login-page {
    .p-4 {
      padding: 0.5rem !important;
    }
    .card-body {
      padding: 0.5rem !important;
    }
  }
  .report-short-box h5 {
    font-size: 18px
  }
  .table th, .table td {
    padding: 0.3rem;
  }
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .product-list {
    .product-item {
      width: 50%;
      // .product-wrap {
      //   flex-direction: row !important;
      //   .product-image {
      //     width: 25%;
      //   }
      // }
    }
  }
  .v-dropdown-menu {
    // top: 70px !important;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // position: fixed !important;
    // transform: unset !important;
    width: 100vw;
    height: calc(100vh - 70px);
    overflow: auto;
    * {
      white-space: normal;
    }
  }
}